var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":_vm.store,"resource":"/customer/contract","reloadable":"","enterable":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.fetch},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.id,"noTranslate":"","bold":""}})],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageOrderStatus',{attrs:{"id":item.status_id}})],1)]}},{key:"purchase_cost_outstanding",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.purchase_cost_outstanding,"currency":item.purchase_cost_currency_id}})],1)]}},{key:"service_fee_outstanding",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.service_fee_outstanding,"currency":item.purchase_cost_currency_id}})],1)]}},{key:"service_fee",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.service_fee,"currency":item.service_fee_currency_id}})],1)]}},{key:"transportation_company",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.transport_organization_id,"noTranslate":"","bold":"","color":"muted"}})],1)]}},{key:"purchasing_company",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.sales_organization_id,"noTranslate":"","bold":"","color":"muted"}})],1)]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.created_at,"small":""}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"status-filter",fn:function(){return [_c('SSelectOrderStatus',{attrs:{"store":"order.statuses","value":_vm.filter.status_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "status_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"transport_organization_id-filter",fn:function(){return [_c('SSelectOrganization',{attrs:{"value":_vm.filter.transport_organization_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "transport_organization_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"sales_organization_id-filter",fn:function(){return [_c('SSelectOrganization',{attrs:{"value":_vm.filter.sales_organization_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "sales_organization_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"created_at-filter",fn:function(){return [_c('TInputDateTimeRange',{attrs:{"value":_vm.dateRange},on:{"update:value":[function($event){_vm.dateRange=$event},_vm.setUpdatedAtFilter]}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }